import { createTheme } from "@mui/material"

const theme = (mode) =>
    createTheme({
        palette: {
            mode: "light",
            tonalOffset: 0.2,
            ochre: {
                main: "#fff",
                light: "#fff",
                dark: "#fff",
                contrastText: "#242105",
            },
            donebutton: {
                main: "#41B06E",
                light: "#41B06E",
                dark: "#41B06E",
                contrastText: "#FFFFFF",
            },
            resetbutton: {
                main: "#FF0000",
                light: "#FF0000",
                dark: "#FF0000",
                contrastText: "#FFFFFF",
            },
            undobutton: {
                main: "#E97132",
                light: "#E97132",
                dark: "#E97132",
                contrastText: "#FFFFFF",
            },
            redobutton: {
                main: "#EAB200",
                light: "#EAB200",
                dark: "#EAB200",
                contrastText: "#FFFFFF",
            },
        },
        /**
        shape: {
            borderRadius: 20,
        },
        spacing: 4,
        */
        typography: {
            fontFamily: [
                "Helvetica",
                '"Helvetica Neue"',
                "-apple-system",
                "BlinkMacSystemFont",
                '"Segoe UI"',
                "Roboto",
            ].join(","),
            allVariants: {
                color: "var(--black)",
            },
        },

        /**
         * over-ride the default color for the card
         */
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundColor: "var(--background)",
                    },
                },
            },
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: "var(--background)",
                        zIndex: 1201,
                    },
                },
            },
            MuiButton: {
                defaultProps: {
                    size: "large",
                    variant: "contained",
                    color: "ochre",
                },
            },
        },
    })

export default theme
