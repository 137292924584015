import RedoRoundedIcon from "@mui/icons-material/RedoRounded"
import { Button } from "@mui/material"

export const RedoButton = ({ onClick, disabled }) => (
    <Button
        color="redobutton"
        startIcon={<RedoRoundedIcon />}
        onClick={onClick}
        disabled={disabled}
    >
        REDO
    </Button>
)
