import React, { useEffect, useState } from "react"
import axios from "axios"
//import styled from "styled-components"
import {
    Box,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableRow,
    AppBar,
    Toolbar,
    IconButton,
} from "@mui/material"
import Avatar from "@mui/material/Avatar"
import { teal } from "@mui/material/colors"
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp"
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown"
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft"
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight"

import { useWindowDimensions } from "../../util"
import { Mazes } from "./consts/MazeData"
import Sprite from "./Sprite"
import { DoneButton } from "../common/buttons/DoneButton"
import { ResetButton } from "../common/buttons/ResetButton"

//import "./styles/styles.scss"

/**
 *
const Success = styled.div`
    color: ${(props) => props.theme.text};
    transition: opacity 0.2s ease;
    padding: 5px 5px;
    opacity: ${(props) => (props.gameSuccess === "success" ? "1" : "0")};
`
const ShowMessages = styled.div`
    color: ${(props) => props.theme.text};
    transition: opacity 0.2s ease;
    padding: 5px 5px;
`
 */

const baseUrl = "/api/mazes"
const Maze = (props) => {
    /**
     * make icon buttons
     */

    const AvatarButtonUp = () => {
        return (
            <IconButton onClick={upButtonClick}>
                <Avatar sx={{ bgcolor: teal[200], width: 56, height: 56 }}>
                    <KeyboardDoubleArrowUpIcon sx={{ fontSize: "2em" }} />
                </Avatar>
            </IconButton>
        )
    }
    const AvatarButtonDown = () => {
        return (
            <IconButton onClick={downButtonClick}>
                <Avatar sx={{ bgcolor: teal[200], width: 56, height: 56 }}>
                    <KeyboardDoubleArrowDownIcon sx={{ fontSize: "2em" }} />
                </Avatar>
            </IconButton>
        )
    }
    const AvatarButtonLeft = () => {
        return (
            <IconButton onClick={leftButtonClick}>
                <Avatar sx={{ bgcolor: teal[200], width: 56, height: 56 }}>
                    <KeyboardDoubleArrowLeftIcon sx={{ fontSize: "2em" }} />
                </Avatar>
            </IconButton>
        )
    }
    const AvatarButtonRight = () => {
        return (
            <IconButton onClick={rightButtonClick}>
                <Avatar sx={{ bgcolor: teal[200], width: 56, height: 56 }}>
                    <KeyboardDoubleArrowRightIcon sx={{ fontSize: "2em" }} />
                </Avatar>
            </IconButton>
        )
    }

    /**
     * set tileDim based on windows size
     */
    const maze = Mazes[props.mazeLevel]
    const { height, width } = useWindowDimensions()
    let tileDim = maze.tileDimMin
    if (height > 700) {
        tileDim = maze.tileDimMax
    }

    let mazeHeight = maze.mazeMap.length * tileDim + "px"
    let mazeWidth = maze.mazeMap[0].length * tileDim + "px"

    //const [startTime, setStartTime] = useState(0)
    const [startTimeStr, setStartTimeStr] = useState("")

    const [gameSuccess, setGameSuccess] = useState("")
    const [spriteCollide, setSpriteCollide] = useState("")
    const [userPosition, setUserPosition] = useState([
        maze.player.y,
        maze.player.x,
    ])

    /**
     * useEffect hook to show success
     */
    const checkGameStatus = function (playerX, playerY, goalX, goalY) {
        if (playerY === goalY && playerX === goalX) {
            setGameSuccess("success")
        } else {
            setGameSuccess("")
        }
    }

    /**
     * reset the game
     */
    const handleReset = () => {
        setTimeout(() => {
            window.location.reload(false)
        }, 500)
        console.log("page to reload")
    }

    const handleDone = () => {
        let data = {
            userId: localStorage.getItem("user_id"),
            q_id: maze.qid,
            mazeID: maze.id,

            startTime: startTimeStr.toString(),
            endTime: Date().toLocaleString().toString(),

            mazeMap: maze.mazeMap.toString(),
            gamePath: maze.gamePath.toString(),
            gameResult: gameSuccess,
        }

        ;(async function () {
            await axios.post(`${baseUrl}/`, data).then((response) => {
                window.location.assign(maze.nextPage)
            })
        })()
    }

    useEffect(() => {
        checkGameStatus(maze.player.x, maze.player.y, maze.goal.x, maze.goal.y)
    })

    /**
     * functions to move the sprites
     * @param {}
     * @returns
     */
    const moveSprite = function (direction) {
        const [i, j] = userPosition
        const newGamePath = maze.gamePath
        if (
            direction === "ArrowLeft" ||
            direction === "KeyA" ||
            direction === "leftButtonClick"
        ) {
            if (
                maze.player.x === 0 ||
                maze.mazeMap[maze.player.y][maze.player.x].substr(1, 1) === "2"
            ) {
                collide()
                return
            }
            setUserPosition([(maze.player.y = i), (maze.player.x = j - 1)])
            newGamePath.push([i, j - 1])
            maze.gamePath = newGamePath
        }
        if (
            direction === "ArrowUp" ||
            direction === "KeyW" ||
            direction === "upButtonClick"
        ) {
            if (
                maze.player.y === 0 ||
                maze.mazeMap[maze.player.y][maze.player.x].substr(3, 1) === "2"
            ) {
                collide()
                return
            }
            setUserPosition([(maze.player.y = i - 1), (maze.player.x = j)])
            newGamePath.push([i - 1, j])
            maze.gamePath = newGamePath
        }
        if (
            direction === "ArrowRight" ||
            direction === "KeyD" ||
            direction === "rightButtonClick"
        ) {
            if (
                maze.player.x === maze.mazeMap[maze.player.y].length - 1 ||
                maze.mazeMap[maze.player.y][maze.player.x].substr(2, 1) === "2"
            ) {
                collide()
                return
            }
            setUserPosition([(maze.player.y = i), (maze.player.x = j + 1)])
            newGamePath.push([i, j + 1])
            maze.gamePath = newGamePath
        }
        if (
            direction === "ArrowDown" ||
            direction === "KeyS" ||
            direction === "downButtonClick"
        ) {
            if (
                maze.player.y === maze.mazeMap.length - 1 ||
                maze.mazeMap[maze.player.y][maze.player.x].substr(4, 1) === "2"
            ) {
                collide()
                return
            }
            setUserPosition([(maze.player.y = i + 1), (maze.player.x = j)])
            newGamePath.push([i + 1, j])
            maze.gamePath = newGamePath
        }
    }

    /**
     * function to move sprites
     * on mouse clicks.
     * @param {}
     * @returns
     */
    const collide = () => {
        setSpriteCollide("collide")
        setTimeout(() => {
            setSpriteCollide("")
        }, 200)
    }
    const handleMove = (e) => {
        e.preventDefault()
        moveSprite(e.code)
    }
    /**
     *
     */
    const upButtonClick = (e) => {
        e.preventDefault()
        moveSprite("upButtonClick")
    }
    const downButtonClick = (e) => {
        e.preventDefault()
        moveSprite("downButtonClick")
    }
    const leftButtonClick = (e) => {
        e.preventDefault()
        moveSprite("leftButtonClick")
    }
    const rightButtonClick = (e) => {
        e.preventDefault()
        moveSprite("rightButtonClick")
    }

    return (
        <>
            <Box
                onKeyDown={handleMove}
                tabIndex={-1}
                sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                    width: { mazeWidth },
                    height: { mazeHeight },
                    top: "3rem",
                    outline: "none !important",
                }}
            >
                <TableContainer>
                    <Table sx={{ width: "auto", height: "auto" }}>
                        <TableBody>
                            {maze.mazeMap.map((row, rowIndex) => (
                                <TableRow
                                    sx={{
                                        height: tileDim + "px",
                                    }}
                                    key={rowIndex}
                                >
                                    {maze.mazeMap[rowIndex].map(
                                        (col, colIndex) => {
                                            /**
                                             * color the path
                                             */
                                            let floorColor = maze.floorColor
                                            for (
                                                var a = 0;
                                                a < maze.gamePath.length;
                                                a++
                                            ) {
                                                if (
                                                    maze.gamePath[a][0] ===
                                                        rowIndex &&
                                                    maze.gamePath[a][1] ===
                                                        colIndex
                                                ) {
                                                    floorColor = maze.pathColor
                                                }
                                            }

                                            /**
                                             * generate walls
                                             */
                                            let wallLineStyle =
                                                "2px solid " + maze.wallColor
                                            let floorLineStyle = "0px solid "
                                            let cellValue =
                                                maze.mazeMap[rowIndex][
                                                    colIndex
                                                ].trim()

                                            let wallTopBorder = ""
                                            let wallBottomBorder = ""
                                            let wallLeftBorder = ""
                                            let wallRightBorder = ""

                                            if (
                                                cellValue.substr(1, 1) === "2"
                                            ) {
                                                wallLeftBorder = wallLineStyle
                                            } else {
                                                wallLeftBorder = floorLineStyle
                                            }

                                            if (
                                                cellValue.substr(2, 1) === "2"
                                            ) {
                                                wallRightBorder = wallLineStyle
                                            } else {
                                                wallRightBorder = floorLineStyle
                                            }

                                            if (
                                                cellValue.substr(3, 1) === "2"
                                            ) {
                                                wallTopBorder = wallLineStyle
                                            } else {
                                                wallTopBorder = floorLineStyle
                                            }

                                            if (
                                                cellValue.substr(4, 1) === "2"
                                            ) {
                                                wallBottomBorder = wallLineStyle
                                            } else {
                                                wallBottomBorder =
                                                    floorLineStyle
                                            }

                                            return (
                                                <TableCell
                                                    sx={{
                                                        padding: "0",
                                                        borderBottom:
                                                            wallBottomBorder,

                                                        borderTop:
                                                            wallTopBorder,

                                                        borderRight:
                                                            wallRightBorder,

                                                        borderLeft:
                                                            wallLeftBorder,

                                                        backgroundColor:
                                                            maze.mazeMap[
                                                                rowIndex
                                                            ][colIndex].substr(
                                                                0,
                                                                1
                                                            ) === "0"
                                                                ? floorColor
                                                                : maze.wallColor,
                                                        width: tileDim + "px",
                                                    }}
                                                    key={colIndex}
                                                ></TableCell>
                                            )
                                        }
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/**
                 * Sprites
                 **/}
                <Sprite
                    spriteType={maze.player}
                    tileDim={tileDim}
                    spriteCollide={spriteCollide}
                />
                <Sprite
                    spriteType={maze.goal}
                    tileDim={tileDim}
                    spriteCollide={""}
                />
                {/**
                 * controls
                 **/}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                    }}
                >
                    <AvatarButtonUp />
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <AvatarButtonLeft />
                        <AvatarButtonRight />
                    </Box>
                    <AvatarButtonDown />
                </Box>
            </Box>

            {/**
             * success message
            <Success gameSuccess={gameSuccess}>
                Congratulations!!!. {maze.gamePath.length} steps to get there!
            </Success>
            <ShowMessages>
                Window height is {height} and width is {width}!
            </ShowMessages>
             **/}

            <AppBar
                position="fixed"
                color="primary"
                sx={{ top: "auto", bottom: 0 }}
            >
                <Toolbar sx={{ justifyContent: "center" }}>
                    <Box
                        sx={{
                            display: "flex",
                            gap: 4,
                        }}
                    >
                        <ResetButton onClick={handleReset} />
                        <DoneButton onClick={handleDone} />
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    )
}
export default Maze
