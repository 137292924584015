import Box from "@mui/material/Box"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import useMediaQuery from "@mui/material/useMediaQuery"

export default function AppBarC(props) {
    let pageTitle = ""
    const baseImgUrl = "/assets/"
    const url = baseImgUrl + "cty_logo_small_horizontal_blue.png"
    const matches_sm = useMediaQuery((theme) => theme.breakpoints.up("sm"))
    const matches_md = useMediaQuery((theme) => theme.breakpoints.down("md"))
    const matches_lg = useMediaQuery((theme) => theme.breakpoints.down("lg"))
    const matches_xl = useMediaQuery((theme) => theme.breakpoints.down("xl"))

    if (props.text === "ss") {
        pageTitle = "Survey "
    } else if (props.text === "pp") {
        pageTitle = "Practice Puzzle "
    } else if (props.text === "t1") {
        pageTitle = "Puzzle 1 Survey "
    } else if (props.text === "t2") {
        pageTitle = "Puzzle 2 Survey "
    } else if (props.text === "t3") {
        pageTitle = "Final Survey "
    } else if (props.text === "gd") {
        pageTitle = "Puzzle Directions "
    } else if (
        props.text === "e1" ||
        props.text === "e2" ||
        props.text === "e3"
    ) {
        pageTitle = "Example Puzzle"
    } else if (props.text === "rp" || props.text === "er") {
        pageTitle = ""
    } else if (props.text !== "") {
        pageTitle = "Puzzle " + props.text + " of 17"
    } else {
        pageTitle = "Login "
    }
    return (
        <>
            <AppBar sx={{ position: "fixed", height: "90px" }}>
                <Toolbar sx={{ display: "flex", flexGrow: 1 }}>
                    <Grid container sx={{ alignItems: "center" }}>
                        <Grid item xs={5}>
                            <Box
                                component="img"
                                sx={{
                                    width: 250,
                                }}
                                alt="CTY logo."
                                src={url}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <Typography
                                variant="h5"
                                component="div"
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    ml: 10,
                                    color: "#002D72",
                                }}
                            >
                                {pageTitle}
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </>
    )
}
