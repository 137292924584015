import UndoRoundedIcon from "@mui/icons-material/UndoRounded"
import { Button } from "@mui/material"

export const UndoButton = ({ onClick, disabled }) => (
    <Button
        color="undobutton"
        startIcon={<UndoRoundedIcon />}
        onClick={onClick}
        disabled={disabled}
    >
        UNDO
    </Button>
)
