import RestartAltIcon from "@mui/icons-material/RestartAlt"
import { Button } from "@mui/material"

export const ResetButton = ({ onClick, disabled }) => (
    <Button
        color="resetbutton"
        startIcon={<RestartAltIcon />}
        onClick={onClick}
        disabled={disabled}
    >
        RESET
    </Button>
)
