import React from "react"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"

const BasicCard = ({ header, content }) => {
    return (
        <Card
            sx={{
                boxShadow: "0",
            }}
        >
            {header}
            <CardContent
                sx={{
                    position: "relative",
                    p: "0",
                    width: "max-content",
                }}
            >
                {content}
            </CardContent>
        </Card>
    )
}

export default BasicCard
