import React from "react"
import { Button, Box, AppBar, Toolbar } from "@mui/material"

import "./styles/direction.css"

const baseImgUrl = "/assets/"

const NoPhone = () => {
    return (
        <div className="direction-page">
            <div className="direction-page-content">
                <h2 className="direction-page-left-align">
                    Currently, our website is <b>not compatible with being displayed on phone-size devices</b>.
                    We kindly request that you switch to a device with a bigger display (for example, tablet, laptop, or computer).
                    Thank you for your understanding and cooperation.
                </h2>
            </div>

            <div>
                <h2 className="centered-text">
                    <a href="https://dsa.cty.jhu.edu/" target="_blank" rel="noopener noreferrer">https://dsa.cty.jhu.edu/</a>
                </h2>
            </div>

            <AppBar
                position="fixed"
                color="primary"
                sx={{ top: "auto", bottom: 0 }}
            >
                <Toolbar sx={{ justifyContent: "center" }}>
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default NoPhone
