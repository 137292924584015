import React from "react"
import App from "./App"
import theme from "./theme"
import { ThemeProvider } from "@mui/material/styles"
import { createRoot } from "react-dom/client"
import { CssBaseline, GlobalStyles } from "@mui/material"
import { BrowserRouter } from "react-router-dom"
import reportWebVitals from "./reportWebVitals"

const globalStyles = (
    <GlobalStyles
        styles={(theme) => ({
            ":root": {
                /* Colors */
                "--black": "#43572E",
                "--grey": "#a4b2bc",
                "--white": "#fff",
                "--background": "#ffffff",
            },
            /**
            "*, *::before, *::after": {
                margin: 0,
                padding: 0,
                "box-sizing": "border-box",
            },
            */
            body: {
                backgroundColor: "var(--background)",
            },
            // ... You can add more global styles here
        })}
    />
)

// From react 18 we should use createRoot instead of ReactDOM
const rootElement = document.getElementById("root")
const root = createRoot(rootElement) // createRoot(container!) if you use TypeScript
root.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        {globalStyles}
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ThemeProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
