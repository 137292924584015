import { keyframes } from "@emotion/react"
import isPropValid from "@emotion/is-prop-valid"
import styled from "@emotion/styled"

const wobble = keyframes`
0% { transform: scale(0.85) translate(1px, 1px); }
10% { transform: scale(0.85) translate(-1px, -2px); }
20% { transform: scale(0.85) translate(-3px, 0px); }
30% { transform: scale(0.85) translate(3px, 2px); }
40% { transform: scale(0.85) translate(1px, -1px); }
50% { transform: scale(0.85) translate(-1px, 2px); }
60% { transform: scale(0.85) translate(-3px, 1px); }
70% { transform: scale(0.85) translate(3px, 1px); }
80% { transform: scale(0.85) translate(-1px, -1px); }
90% { transform: scale(0.85) translate(1px, 2px); }
100% { transform: scale(0.85) translate(1px, -2px); }
`

const SpriteCSS = styled("div", {
    shouldForwardProp: (prop) => isPropValid(prop) && prop !== "spritetop",
})((props) => ({
    top: props.spritetop,
    left: props.spriteleft,
    height: props.spriteheight,
    width: props.spritewidth,
    borderRadius: props.spriteborderradius,
    transformOrigin: "center",
    transform: "scale(0.85)",
    position: "absolute",
    backgroundColor: props.spritecolor,
    animation:
        props.spritecollide === "collide" ? `${wobble} 0.5s infinite` : "",
    transition:
        props.spritecollide === "collide" ? "background-color 0.2s" : "",
}))

const Sprite = (props) => {
    return (
        <SpriteCSS
            spriteleft={props.spriteType.x * props.tileDim + "px"}
            spritetop={props.spriteType.y * props.tileDim + "px"}
            spriteborderradius={props.tileDim + "px"}
            spritewidth={props.tileDim + "px"}
            spriteheight={props.tileDim + "px"}
            spritecolor={props.spriteType.color}
            spritecollide={props.spriteCollide}
        ></SpriteCSS>
    )
}
export default Sprite
