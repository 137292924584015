import React from "react"
import { Button, Box, AppBar, Toolbar } from "@mui/material"

import "./styles/direction.css"
import BasicCard from "../../components/common/basiccard/BasicCard"

const baseImgUrl = "/assets/"

const PuzzleDirection = (props) => {
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "80vh",
                    gap: 4,
                }}
            >
                <BasicCard
                    content={
                        <>
                            <video
                                src={baseImgUrl + props.videoSrc}
                                poster={baseImgUrl + props.videoPoster}
                                controls
                                className="video-size-isometric"
                            >
                                Your browser does not support the video tag.
                            </video>
                        </>
                    }
                />
            </Box>
            <AppBar
                position="fixed"
                color="primary"
                sx={{ top: "auto", bottom: 0 }}
            >
                <Toolbar sx={{ justifyContent: "center" }}>
                    <Button
                        onClick={() =>
                            window.location.assign(props.nextPagePath)
                        }
                    >
                        Next
                    </Button>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default PuzzleDirection
