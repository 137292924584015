import React, { useEffect, useState } from "react"
import { Button, Box, AppBar, Toolbar } from "@mui/material"
import axios from "axios"

import "./styles/ifstyle.css"

import { Row } from "antd"

import { ballSortExamplePath } from "../../config/redirectPath"
import BasicCard from "../common/basiccard/BasicCard"
import { DoneButton } from "../common/buttons/DoneButton"
import { ResetButton } from "../common/buttons/ResetButton"
import { UndoButton } from "../common/buttons/UndoButton"
import { RedoButton } from "../common/buttons/RedoButton"

const baseImgUrl = "/assets/"

const emptyBoard = [0, 0, 0, 0]

const NextPagePath = ballSortExamplePath
const ImageName = "example_2x2.jpg"
const QID = "if-example"

function imageExists(url) {
    return new Promise((resolve) => {
        var img = new Image()
        img.addEventListener("load", () => resolve(true))
        img.addEventListener("error", () => resolve(false))
        img.src = url
    })
}

function Square(props) {
    return (
        <button
            className="if-square2"
            style={{ background: "#FFFFFF" }}
            onClick={props.onClick}
        >
            {props.value}
        </button>
    )
}

class Board extends React.Component {
    renderSquare(i) {
        return (
            <Square
                num={i}
                value={this.props.squares[i]}
                onClick={() => this.props.onClick(i)}
            />
        )
    }

    render() {
        return (
            <div>
                <Row>
                    {this.renderSquare(0)}
                    {this.renderSquare(1)}
                </Row>
                <Row>
                    {this.renderSquare(2)}
                    {this.renderSquare(3)}
                </Row>
            </div>
        )
    }
}

const IfExample = () => {
    const [startTime, setStartTime] = useState(0)
    const [startTimeStr, setStartTimeStr] = useState("")

    const [imagePath, setImagePath] = useState("")

    const [numClicks, setNumClicks] = useState(0)
    const [numUndos, setNumUndos] = useState(0)
    const [numRedos, setNumRedos] = useState(0)
    const [numResets, setNumResets] = useState(0)

    const [initBoard, setInitBoard] = useState(emptyBoard)
    const [userBoard, setUserBoard] = useState(emptyBoard)
    const [pastBoards, setPastBoards] = useState([])

    const [actions, setActions] = useState([])
    const [actionIndexes, setActionIndexes] = useState([])
    const [actionTimes, setActionTimes] = useState([])

    const [pastActions, setPastActions] = useState([])
    const [pastResets, setPastResets] = useState([])
    const [pastUndos, setPastUndos] = useState([])

    const handleImageLoaded = () => {
        let timeNowStr = Date().toLocaleString()
        let timeNow = Date.now()
        setStartTimeStr(timeNowStr)
        setStartTime(timeNow)
    }

    const handleClick = (i) => {
        setNumClicks(numClicks + 1)

        const newSquares = userBoard.slice()

        if (userBoard[i] === 10) {
            newSquares[i] = 0
        } else {
            newSquares[i] = userBoard[i] + 1
        }
        setUserBoard(newSquares)

        // record board
        const pastBoard = pastBoards.slice()
        pastBoard.push(newSquares.toString())
        setPastBoards(pastBoard)

        // record action
        const action = actions.slice()
        action.push("click")
        setActions(action)

        const actionIndex = actionIndexes.slice()
        actionIndex.push(i)
        setActionIndexes(actionIndex)

        const actionTime = actionTimes.slice()
        let timeNow = Date.now() - startTime
        let timeNowString = timeNow.toString() + ","
        actionTime.push(timeNowString)
        setActionTimes(actionTime)

        // record action for undo
        const pastAction = pastActions.slice()
        pastAction.push(i)
        setPastActions(pastAction)
    }

    const handleUndo = () => {
        setNumUndos(numUndos + 1)

        if (pastActions.length >= 1) {
            const i = pastActions.pop()

            if (i === -1) {
                let beforeReset = pastResets.pop().slice()
                setUserBoard(beforeReset)

                // record undo resets
                const pastUndo = pastUndos.slice()
                pastUndo.push(-1)
                setPastUndos(pastUndo)

                // record board
                const pastBoard = pastBoards.slice()
                pastBoard.push(beforeReset.toString())
                setPastBoards(pastBoard)
            } else {
                const newSquares = userBoard.slice()

                // record undo
                const pastUndo = pastUndos.slice()
                pastUndo.push(i)
                setPastUndos(pastUndo)

                if (userBoard[i] === 0) {
                    newSquares[i] = 10
                } else {
                    newSquares[i] = userBoard[i] - 1
                }
                setUserBoard(newSquares)

                // record board
                const pastBoard = pastBoards.slice()
                pastBoard.push(newSquares.toString())
                setPastBoards(pastBoard)
            }

            // record action
            const action = actions.slice()
            action.push("undo")
            setActions(action)

            const actionIndex = actionIndexes.slice()
            actionIndex.push(i)
            setActionIndexes(actionIndex)

            const actionTime = actionTimes.slice()
            let timeNow = Date.now() - startTime
            let timeNowString = timeNow.toString() + ","
            actionTime.push(timeNowString)
            setActionTimes(actionTime)
        } else {
            console.log("nothing to undo")

            const action = actions.slice()
            action.push("undo-failed")
            setActions(action)

            const actionIndex = actionIndexes.slice()
            actionIndex.push(-1)
            setActionIndexes(actionIndex)

            const actionTime = actionTimes.slice()
            let timeNow = Date.now() - startTime
            let timeNowString = timeNow.toString() + ","
            actionTime.push(timeNowString)
            setActionTimes(actionTime)

            const pastBoard = pastBoards.slice()
            pastBoard.push(userBoard.toString())
            setPastBoards(pastBoard)
        }
    }

    const handleRedo = () => {
        setNumRedos(numRedos + 1)

        if (pastUndos.length >= 1) {
            const i = pastUndos.pop()

            if (i === -1) {
                // record board
                const pastReset = pastResets.slice()
                pastReset.push(userBoard)
                setPastResets(pastReset)

                setUserBoard(initBoard)

                // record undo resets
                const pastAction = pastActions.slice()
                pastAction.push(-1)
                setPastActions(pastAction)

                // record board
                const pastBoard = pastBoards.slice()
                pastBoard.push(initBoard.toString())
                setPastBoards(pastBoard)
            } else {
                const newSquares = userBoard.slice()

                // record undo
                const pastAction = pastActions.slice()
                pastAction.push(i)
                setPastActions(pastAction)

                if (userBoard[i] === 10) {
                    newSquares[i] = 0
                } else {
                    newSquares[i] = userBoard[i] + 1
                }
                setUserBoard(newSquares)

                // record board
                const pastBoard = pastBoards.slice()
                pastBoard.push(newSquares.toString())
                setPastBoards(pastBoard)
            }

            // record action
            const action = actions.slice()
            action.push("redo")
            setActions(action)

            const actionIndex = actionIndexes.slice()
            actionIndex.push(i)
            setActionIndexes(actionIndex)

            const actionTime = actionTimes.slice()
            let timeNow = Date.now() - startTime
            let timeNowString = timeNow.toString() + ","
            actionTime.push(timeNowString)
            setActionTimes(actionTime)
        } else {
            console.log("nothing to redo")

            // record action
            const action = actions.slice()
            action.push("redo-failed")
            setActions(action)

            const actionIndex = actionIndexes.slice()
            actionIndex.push(-1)
            setActionIndexes(actionIndex)

            const actionTime = actionTimes.slice()
            let timeNow = Date.now() - startTime
            let timeNowString = timeNow.toString() + ","
            actionTime.push(timeNowString)
            setActionTimes(actionTime)

            const pastBoard = pastBoards.slice()
            pastBoard.push(userBoard.toString())
            setPastBoards(pastBoard)
        }
    }

    const handleReset = () => {
        setNumResets(numResets + 1)

        // record board
        const pastReset = pastResets.slice()
        pastReset.push(userBoard)
        setPastResets(pastReset)

        // clear board
        setUserBoard(initBoard)

        // record board
        const pastBoard = pastBoards.slice()
        pastBoard.push(initBoard.toString())
        setPastBoards(pastBoard)

        // record action
        const action = actions.slice()
        action.push("reset")
        setActions(action)

        const actionIndex = actionIndexes.slice()
        actionIndex.push(-1)
        setActionIndexes(actionIndex)

        const actionTime = actionTimes.slice()
        let timeNow = Date.now() - startTime
        let timeNowString = timeNow.toString() + ","
        actionTime.push(timeNowString)
        setActionTimes(actionTime)

        // record action for undo
        const pastAction = pastActions.slice()
        pastAction.push(-1)
        setPastActions(pastAction)
    }

    const handleDone = () => {
        storeData(userBoard)
    }

    const storeData = (passInUpdated) => {
        let data = {
            userId: localStorage.getItem("user_id"),
            q_id: QID,

            numClicks: numClicks,
            numUndos: numUndos,
            numRedos: numRedos,
            numResets: numResets,

            userResponse: passInUpdated.toString(),
            allPastGameStates: pastBoards.toString(),

            actions: actions.toString(),
            actionIndexes: actionIndexes.toString(),
            actionTimes: actionTimes.toString(),

            startTime: startTimeStr.toString(),
            endTime: Date().toLocaleString().toString(),
        }

        window.location.assign(NextPagePath)
    }

    useEffect(() => {
        setInitBoard(emptyBoard)
        setUserBoard(emptyBoard)

        const url = baseImgUrl + ImageName
        setImagePath(url)
        imageExists(url)
    }, [])

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "80vh",
                    gap: 4,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                    }}
                >
                    <UndoButton
                        onClick={handleUndo}
                        disabled={pastActions.length === 0}
                    />
                    <RedoButton
                        onClick={handleRedo}
                        disabled={pastUndos.length === 0}
                    />
                </Box>

                <BasicCard
                    content={
                        <img
                            className="if-task-image-example"
                            onLoad={handleImageLoaded}
                            src={imagePath}
                            alt="isometric forms 2 x 2 task"
                            style={{ maxWidth: "100%", height: "auto" }}
                        ></img>
                    }
                />
                <BasicCard
                    content={
                        <Board
                            squares={userBoard}
                            onClick={(j) => handleClick(j)}
                        />
                    }
                />
            </Box>

            <AppBar
                position="fixed"
                color="primary"
                sx={{ top: "auto", bottom: 0 }}
            >
                <Toolbar sx={{ justifyContent: "center" }}>
                    <Box
                        sx={{
                            display: "flex",
                            gap: 4,
                        }}
                    >
                        <ResetButton
                            onClick={handleReset}
                            disabled={
                                JSON.stringify(userBoard) ===
                                JSON.stringify(initBoard)
                            }
                        />
                        <DoneButton
                            onClick={handleDone} // Disable onClick if completed
                        />
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default IfExample
