import {
    preStudySurveyPath,
    ballSortDirectionPath,
    ballSortPracticePath,
    ballSortReadyPath,
    ballSortExamplePath,
    ballSort1Path,
    ballSort2Path,
    ballSort3Path,
    ballSort4Path,
    ballSort5Path,
    difficultySurvey1Path,
    difficultySurvey2Path,
    difficultySurvey3Path,
    difficultySurvey4Path,
    difficultySurvey5Path,
    difficultySurvey6Path,
    difficultySurvey7Path,
    difficultySurvey8Path,
    difficultySurvey9Path,
    difficultySurvey10Path,
    difficultySurvey11Path,
    difficultySurvey12Path,
    difficultySurvey13Path,
    difficultySurvey14Path,
    difficultySurvey15Path,
    finalSurvey1Path,
    ifDirectionPath,
    ifExamplePath,
    ifPracticePath,
    ifReadyPath,
    if1Path,
    if2Path,
    if3Path,
    if4Path,
    if5Path,
    finalSurvey2Path,
    nonogramDirectionPath,
    nonogramPracticePath,
    nonogramReadyPath,
    nonogram1Path,
    nonogram2Path,
    nonogram3Path,
    nonogram4Path,
    nonogram5Path,
    finalSurvey3Path,
    nonogramExamplePath,
    mazePath,
    maze1Path,
} from "./config/redirectPath"

// import * as React from "react"
import { useState, useEffect } from "react"
import { useLocation, Routes, Route } from "react-router-dom"

/**
import Logo from "./components/common/logo/Logo"
import Footer from "./components/footer/Footer"
import Navbar from "./components/navbar/Navbar"
import BigTitle from "./components/common/bigtitle/BigTitle"
import ParticlesBG from "./components/common/particles/ParticlesBG"
 */
import GridWrapper from "./components/common/gridwrapper/GridWrapper"
import AppBarC from "./components/common/appbar/AppBarC"
/**
import Maze from "./pages/maze/Maze"
 */
import NoIncognito from "./pages/direction/NoIncognito"
import NoPhone from "./pages/direction/NoPhone"
import Maze from "./components/maze/Maze"

import BallSortPractice from "./components/ballsort/BallSortPractice"
import BallSortReady from "./components/ballsort/BallSortReady"
import BallSortExample from "./components/ballsort/BallSortExample"
import BallSort from "./components/ballsort/BallSort"

import IfExample from "./components/isometric/IsometricExample"
import IfPractice from "./components/isometric/IsometricPractice"
import IsometricReady from "./components/isometric/IsometricReady"
import Isometric1 from "./components/isometric/Isometric1"
import Isometric2 from "./components/isometric/Isometric2"
import Isometric3 from "./components/isometric/Isometric3"
import Isometric4 from "./components/isometric/Isometric4"
import Isometric5 from "./components/isometric/Isometric5"

import NonogramExample from "./components/nonogram/NonogramExample"
import NonogramPractice from "./components/nonogram/NonogramPractice"
import NonogramReady from "./components/nonogram/NonogramReady"
import Nonogram1 from "./components/nonogram/Nonogram1"
import Nonogram2 from "./components/nonogram/Nonogram2"
import Nonogram3 from "./components/nonogram/Nonogram3"
import Nonogram4 from "./components/nonogram/Nonogram4"
import Nonogram5 from "./components/nonogram/Nonogram5"

import User from "./pages/user/User"
import PuzzleDirection from "./pages/direction/PuzzleDirection"
import PreStudySurvey from "./pages/surveys/PreStudySurvey"
import TrialSurvey from "./pages/surveys/TrialSurvey"
import DifficultySurvey from "./pages/surveys/DifficultySurvey"
/**
import PreStudySurveyPage from "./pages/surveys/PreStudySurveyPage"
*/

function App(props) {
    const [title, setTitle] = useState(null)

    /**
     * change title on path
     */
    const location = useLocation()
    useEffect(() => {
        const parsedTitle = location.pathname.replace(/\W/g, " ")
        if (parsedTitle === "" || parsedTitle.length === 0) {
            setTitle("Maze")
        } else {
            setTitle(parsedTitle.substring(1, 3))
        }
    }, [location])

    return (
        <>
            <AppBarC text={title} />
            {/**
             * <Navbar />
             * <Logo />
             * <BigTitle text={title} top="10%" left="5%" />
             * <ParticlesBG text={title} />
             * **/}
            <GridWrapper>
                <Routes key={location.pathname} location={location}>
                    <Route path="/" element={<User />} />

                    <Route path="/error/incognito" element={<NoIncognito />} />
                    <Route path="/error/phone" element={<NoPhone />} />

                    <Route
                        path={finalSurvey1Path}
                        element={
                            <TrialSurvey
                                puzzle="isometric_puzzle"
                                nextPagePath={ballSortDirectionPath}
                            />
                        }
                    />
                    <Route
                        path={finalSurvey2Path}
                        element={
                            <TrialSurvey
                                puzzle="ballsort_puzzle"
                                nextPagePath={nonogramDirectionPath}
                            />
                        }
                    />
                    <Route
                        path={finalSurvey3Path}
                        element={
                            <TrialSurvey
                                puzzle="nonogram_puzzle"
                                nextPagePath="https://ern.cty.jhu.edu/course/view.php?id=5"
                            />
                        }
                    />

                    {/**
                     * Maze Path
                     */}
                    <Route path={mazePath} element={<Maze mazeLevel="0" />} />
                    <Route path={maze1Path} element={<Maze mazeLevel="1" />} />

                    <Route
                        path={ballSortDirectionPath}
                        element={
                            <PuzzleDirection
                                videoSrc="Ball Sort with Move 5.6.24.mp4"
                                videoPoster="Ball Sort with Move 5.6.24.jpg"
                                nextPagePath={ballSortPracticePath}
                            />
                        }
                    />
                    <Route
                        path={ballSortPracticePath}
                        element={<BallSortPractice />}
                    />
                    <Route
                        path={ballSortReadyPath}
                        element={<BallSortReady />}
                    />

                    <Route
                        path={ballSortExamplePath}
                        element={<BallSortExample />}
                    />
                    <Route
                        path={ballSort1Path}
                        element={<BallSort ballSortLevel="0" />}
                    />
                    <Route
                        path={ballSort2Path}
                        element={<BallSort ballSortLevel="1" />}
                    />
                    <Route
                        path={ballSort3Path}
                        element={<BallSort ballSortLevel="2" />}
                    />
                    <Route
                        path={ballSort4Path}
                        element={<BallSort ballSortLevel="3" />}
                    />
                    <Route
                        path={ballSort5Path}
                        element={<BallSort ballSortLevel="4" />}
                    />

                    <Route
                        path={ifDirectionPath}
                        element={
                            <PuzzleDirection
                                videoSrc="Isometric Forms Instructions 5.6.24_v2.mp4"
                                videoPoster="Isometric Forms Instructions 5.6.24_v2.jpg"
                                nextPagePath={ifPracticePath}
                            />
                        }
                    />
                    <Route path={ifExamplePath} element={<IfExample />} />
                    <Route path={ifPracticePath} element={<IfPractice />} />
                    <Route path={ifReadyPath} element={<IsometricReady />} />

                    <Route path={if1Path} element={<Isometric1 />} />
                    <Route path={if2Path} element={<Isometric2 />} />
                    <Route path={if3Path} element={<Isometric3 />} />
                    <Route path={if4Path} element={<Isometric4 />} />
                    <Route path={if5Path} element={<Isometric5 />} />

                    <Route
                        path={nonogramDirectionPath}
                        element={
                            <PuzzleDirection
                                videoSrc="Nonograms Instructions 5.6.24.mp4"
                                videoPoster="Nonograms Instructions 5.6.24.jpg"
                                nextPagePath={nonogramPracticePath}
                            />
                        }
                    />
                    <Route
                        path={nonogramExamplePath}
                        element={<NonogramExample />}
                    />
                    <Route
                        path={nonogramPracticePath}
                        element={<NonogramPractice />}
                    />
                    <Route
                        path={nonogramReadyPath}
                        element={<NonogramReady />}
                    />

                    <Route path={nonogram1Path} element={<Nonogram1 />} />
                    <Route path={nonogram2Path} element={<Nonogram2 />} />
                    <Route path={nonogram3Path} element={<Nonogram3 />} />
                    <Route path={nonogram4Path} element={<Nonogram4 />} />
                    <Route path={nonogram5Path} element={<Nonogram5 />} />

                    <Route
                        path={preStudySurveyPath}
                        element={<PreStudySurvey />}
                    />
                    <Route
                        path={difficultySurvey1Path}
                        element={
                            <DifficultySurvey
                                puzzle="if1"
                                nextPagePath={if2Path}
                            />
                        }
                    />
                    <Route
                        path={difficultySurvey2Path}
                        element={
                            <DifficultySurvey
                                puzzle="if2"
                                nextPagePath={if3Path}
                            />
                        }
                    />
                    <Route
                        path={difficultySurvey3Path}
                        element={
                            <DifficultySurvey
                                puzzle="if3"
                                nextPagePath={if4Path}
                            />
                        }
                    />
                    <Route
                        path={difficultySurvey4Path}
                        element={
                            <DifficultySurvey
                                puzzle="if4"
                                nextPagePath={if5Path}
                            />
                        }
                    />
                    <Route
                        path={difficultySurvey5Path}
                        element={
                            <DifficultySurvey
                                puzzle="if5"
                                nextPagePath={finalSurvey1Path}
                            />
                        }
                    />
                    <Route
                        path={difficultySurvey6Path}
                        element={
                            <DifficultySurvey
                                puzzle="ballsort1"
                                nextPagePath={ballSort2Path}
                            />
                        }
                    />
                    <Route
                        path={difficultySurvey7Path}
                        element={
                            <DifficultySurvey
                                puzzle="ballsort2"
                                nextPagePath={ballSort3Path}
                            />
                        }
                    />
                    <Route
                        path={difficultySurvey8Path}
                        element={
                            <DifficultySurvey
                                puzzle="ballsort3"
                                nextPagePath={ballSort4Path}
                            />
                        }
                    />
                    <Route
                        path={difficultySurvey9Path}
                        element={
                            <DifficultySurvey
                                puzzle="ballsort4"
                                nextPagePath={ballSort5Path}
                            />
                        }
                    />
                    <Route
                        path={difficultySurvey10Path}
                        element={
                            <DifficultySurvey
                                puzzle="ballsort5"
                                nextPagePath={finalSurvey2Path}
                            />
                        }
                    />
                    <Route
                        path={difficultySurvey11Path}
                        element={
                            <DifficultySurvey
                                puzzle="nonogram1"
                                nextPagePath={nonogram2Path}
                            />
                        }
                    />
                    <Route
                        path={difficultySurvey12Path}
                        element={
                            <DifficultySurvey
                                puzzle="nonogram2"
                                nextPagePath={nonogram3Path}
                            />
                        }
                    />
                    <Route
                        path={difficultySurvey13Path}
                        element={
                            <DifficultySurvey
                                puzzle="nonogram3"
                                nextPagePath={nonogram4Path}
                            />
                        }
                    />
                    <Route
                        path={difficultySurvey14Path}
                        element={
                            <DifficultySurvey
                                puzzle="nonogram4"
                                nextPagePath={nonogram5Path}
                            />
                        }
                    />
                    <Route
                        path={difficultySurvey15Path}
                        element={
                            <DifficultySurvey
                                puzzle="nonogram5"
                                nextPagePath={finalSurvey3Path}
                            />
                        }
                    />
                </Routes>
            </GridWrapper>

            {/**
            <Footer />
            */}
        </>
    )
}

export default App
