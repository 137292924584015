import React, { useState } from "react"
import { Button, Box, AppBar, Toolbar } from "@mui/material"
import { Form, Radio, Space } from "antd"
import axios from "axios"

import "./styles/index.css"
import { ballSortDirectionPath } from "../../config/redirectPath.js"
import BasicCard from "../../components/common/basiccard/BasicCard"

const baseUrl = "/api/finalsurveys"

const TrialSurvey = (props) => {
    const [form] = Form.useForm()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleFormSubmit = async () => {
        await form
            .validateFields()
            .then((values) => {
                setIsSubmitting(true)
                values.userId = localStorage.getItem("user_id")
                values.q_id = props.puzzle
                ;(async function () {
                    await axios.post(`${baseUrl}/`, values).then((response) => {
                        let nextPagePath = ballSortDirectionPath
                        window.location.assign(props.nextPagePath)
                    })
                })()
            })
            .catch((errorInfo) => {})
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "80vh",
                    gap: 4,
                }}
            >
                <BasicCard
                    content={
                        <Form form={form} layout="vertical">
                            <Form.Item
                                name="directionsDifficulty"
                                label={
                                    <p
                                        style={{
                                            fontSize: "25px",
                                            marginBottom: "5px",
                                        }}
                                    >
                                        How easy were the directions to follow
                                        for the puzzle?
                                    </p>
                                }
                                style={{
                                    fontSize: "26px",
                                    alignContent: "start",
                                    marginTop: 20,
                                }}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Radio.Group
                                    style={{
                                        fontSize: "26px",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignContent: "left",
                                    }}
                                >
                                    <Space
                                        direction="horizontal"
                                        align="start"
                                        style={{ marginLeft: 30 }}
                                    >
                                        <Radio
                                            style={{ fontSize: "22px" }}
                                            value="1"
                                        >
                                            1 - Very easy
                                        </Radio>
                                        <Radio
                                            style={{ fontSize: "22px" }}
                                            value="2"
                                        >
                                            2
                                        </Radio>
                                        <Radio
                                            style={{ fontSize: "22px" }}
                                            value="3"
                                        >
                                            3
                                        </Radio>
                                        <Radio
                                            style={{ fontSize: "22px" }}
                                            value="4"
                                        >
                                            4
                                        </Radio>
                                        <Radio
                                            style={{ fontSize: "22px" }}
                                            value="5"
                                        >
                                            5 - Very difficult
                                        </Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                name="controlDifficulty"
                                label={
                                    <p
                                        style={{
                                            fontSize: "25px",
                                            marginBottom: "5px",
                                        }}
                                    >
                                        How easy was it to use the controls in
                                        the puzzle (movement, response, etc.)?
                                    </p>
                                }
                                style={{
                                    fontSize: "26px",
                                    alignContent: "start",
                                    marginTop: 20,
                                }}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Radio.Group
                                    style={{
                                        fontSize: "26px",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignContent: "left",
                                    }}
                                >
                                    <Space
                                        direction="horizontal"
                                        align="start"
                                        style={{ marginLeft: 30 }}
                                    >
                                        <Radio
                                            style={{ fontSize: "22px" }}
                                            value="1"
                                        >
                                            1 - Very easy
                                        </Radio>
                                        <Radio
                                            style={{ fontSize: "22px" }}
                                            value="2"
                                        >
                                            2
                                        </Radio>
                                        <Radio
                                            style={{ fontSize: "22px" }}
                                            value="3"
                                        >
                                            3
                                        </Radio>
                                        <Radio
                                            style={{ fontSize: "22px" }}
                                            value="4"
                                        >
                                            4
                                        </Radio>
                                        <Radio
                                            style={{ fontSize: "22px" }}
                                            value="5"
                                        >
                                            5 - Very difficult
                                        </Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                name="taskBefore"
                                label={
                                    <p
                                        style={{
                                            fontSize: "25px",
                                            marginBottom: "5px",
                                        }}
                                    >
                                        Have you completed a puzzle like this
                                        before?
                                    </p>
                                }
                                style={{
                                    fontSize: "26px",
                                    alignContent: "start",
                                    marginTop: 20,
                                }}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Radio.Group
                                    style={{
                                        fontSize: "26px",
                                        display: "flex",
                                        flexDirection: "column",
                                        marginBottom: "10px",
                                        alignContent: "left",
                                    }}
                                >
                                    <Space
                                        direction="horizontal"
                                        align="start"
                                        style={{ marginLeft: 30 }}
                                    >
                                        <Radio
                                            style={{ fontSize: "22px" }}
                                            value="1"
                                        >
                                            Yes
                                        </Radio>
                                        <Radio
                                            style={{ fontSize: "22px" }}
                                            value="2"
                                        >
                                            No
                                        </Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Form>
                    }
                />
            </Box>

            <AppBar
                position="fixed"
                color="primary"
                sx={{ top: "auto", bottom: 0 }}
            >
                <Toolbar sx={{ justifyContent: "center" }}>
                    <Button onClick={handleFormSubmit} disabled={isSubmitting}>
                        Submit
                    </Button>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default TrialSurvey
