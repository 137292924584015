import React from "react"
import { Button, Box, Typography, AppBar, Toolbar } from "@mui/material"

import "./styles/readyPage.css"
import {
    ifDirectionPath,
    if1Path,
    ifReadyPath,
} from "../../config/redirectPath"

const IsometricReady = () => {
    const handleStart = () => {
        let nextPagePath = if1Path
        window.location.assign(nextPagePath)
    }

    const handleReviewInstructions = () => {
        let nextPagePath = ifDirectionPath
        window.location.assign(nextPagePath)
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
                gap: 4,
            }}
        >
            <Typography
                variant="h5"
                component="div"
                sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    ml: 8,
                }}
            >
                Ready to start?
            </Typography>

            <AppBar
                position="fixed"
                color="primary"
                sx={{ top: "auto", bottom: 0 }}
            >
                <Toolbar sx={{ justifyContent: "center" }}>
                    <Box
                        sx={{
                            display: "flex",
                            gap: 4,
                        }}
                    >
                        <Button onClick={handleReviewInstructions}>
                            Review Instructions Again
                        </Button>
                        <Button onClick={handleStart}>Start</Button>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default IsometricReady
